<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>
          İleti Yönetim Sistemi Hizmetleri
        </h2>

        <social />

        <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/hs-01.svg" alt />
          </div>
          <div class="half alignleft">
            <div>
              <h3>Hizmet Sağlayıcı kimdir?</h3>
              <p>Telefon, çağrı merkezi, otomatik arama makinesi, akıllı ses kaydedici sistem, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak elektronik ortamda gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü içerikli ileti gönderimi yapan gerçek veya tüzel kişilere hizmet sağlayıcı denir.
              </p>
            </div>
          </div>

          <div class="half alignleft">
            <div>
              <h3>Ticari elektronik ileti nedir?</h3>
              <p>Hizmet sağlayıcıların, mal ve hizmetlerini pazarlamak, işletmesini tanıtmak ya da kutlama ve temenni gibi içeriklerle tanınırlığını artırmak amacıyla alıcıların elektronik iletişim adreslerine promosyon, kampanya, indirim, hediye içerikli gönderdiği iletilerdir. Bu iletilerin gönderilebilmesi için alıcıların onayı gerekir.
Ancak, alınan veya dolaylı yoldan kullandırılan bir hizmete ilişkin yapılan teslimat, borç, fatura içerikli iletilerin gönderiminde onay aranmaz.
              </p>
            </div>
          </div>
        </div>

        <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/hs-02.svg" alt />
          </div>

          <div class="p70">
            <h3>Her onay için bir bildirim</h3>
<p>Hizmet sağlayıcılar, İYS dışında aldıkları onay ve ret bildirimlerini, web arayüzü veya teknik entegrasyon aracılığıyla İYS’ye iletecektir. İYS, kendisine iletilen onaylar için alıcılara bildirim gönderecektir. Alıcılar dilerse bu onay bildirimleri üzerinden çıkış haklarını kullanabilecektir.</p>
          </div>
        </div>

        <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/hs-03.svg" alt />
          </div>

          <div class="p70">
            <h3>Onay almak hızlı, kolay ve güvenli</h3>
            <!-- <p>Dileyen hizmet sağlayıcılar onaylarını İYS aracılığıyla (İYS Yolu) alabilir. Bu durumda ispat yükümlülüğü hizmet sağlayıcıdan kalkar. -->
            <p>Dileyen hizmet sağlayıcılar onaylarını <strong><a href="https://via.iletiys.com.tr/" style="color:#46E5BE" >İYS - ViA</a></strong> aracılığıyla alabilir. İYS üzerinden alınacak ticari elektronik ileti (ETK) onaylarında ispat yükümlülüğü hizmet sağlayıcıdan kalkar.
            </p>
          </div>
        </div>

        <!-- <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/hs-04.svg" alt />
          </div>
          <div class="p70">
            <h3>Raporlar her an elinizin altında</h3>
            <p>
              İleti Yönetim Sistemi'ne aktarılan onay ve ret bildirimleri günlük
              olarak raporlanır.
              <br />Müşterilerinizin tüm izin hareketleri, her bir iletişim
              kanalı için ayrı ayrı kayıt altında tutulur.
            </p>
          </div>
        </div> -->


        <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/hs-07.svg" alt />
          </div>
          <div class="p70">
            <h3>İletiler için dönüm noktası</h3>
<p>Aracı hizmet sağlayıcılar, alıcıların onayı olup olmadığını İYS üzerinden sorgulayacaktır ve hizmet sağlayıcılar, İYS üzerinde onayı olmayan alıcılara, ticari elektronik iletilerini gönderemeyecektir.</p>

          </div>
        </div>

          <!-- <div
              @click="SET_MODAL('ModalPreFaz'); SET_NOTIFY_REQUESTER_TYPE(1);"
              class="btn second"
            >
              <a>Beni Haberdar Et</a>
            </div>-->

        <!-- <div class="detailbox alt mb2">
          <div class="infoline">
            <img src="@/assets/img/ico_info.svg" alt />
            <div class="desc">
              150 bin adet ve altında ticari elektronik ileti onayı olan hizmet sağlayıcıların mevcut izinlerini 31 Mayıs 2021 tarihine kadar İYS’ye aktarması gerekmektedir. Bu tarihe kadar aktarılmayan izinler geçersiz sayılacaktır.
            </div>

            <div class="btn">
              <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k</p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "Homepage",
  components: { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>
