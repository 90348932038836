import { render, staticRenderFns } from "./Nasil.vue?vue&type=template&id=9ab7d5a6&"
import script from "./Nasil.vue?vue&type=script&lang=js&"
export * from "./Nasil.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports